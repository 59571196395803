import "./Embroidery.scss"
import { Fancybox } from "@fancyapps/ui";
import { embroidery_cards } from '../../data/constant';

Fancybox.bind('[data-fancybox="gallery"]');

function Embroidery() {
  return (
    <div className='embroidery'>
      <div className="embroidery-heading">
        <div className="horizontal-line"></div>
        <span>EMBROIDERY BLOUSES</span>
        <div className="horizontal-line"></div>
      </div>
      <div className="container">
        <div className="grid-container">
          {
            embroidery_cards.map((item, index) => (
              <a key={index} href={item} data-fancybox="gallery" className="image-box">
                <img src={item} alt={index} />
              </a>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Embroidery