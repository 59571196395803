import Carousel from 'react-bootstrap/Carousel';
import { banners } from '../../data/constant';
import './Banner.scss'

function Banner() {
    return (
        <Carousel fade>
            {
                banners.map((item, index) => (
                    <Carousel.Item key={index}>
                        <img className="banner-image" src={item} alt={index} />
                    </Carousel.Item>
                ))
            }
        </Carousel>
    )
}

export default Banner