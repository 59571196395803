import { NavLink } from 'react-router-dom'
import menus from '../../data/menus'
import './Header.scss'
import { BsEnvelopeFill, BsFacebook, BsInstagram, BsTelephoneFill, BsWhatsapp } from 'react-icons/bs'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header() {
  if (window.innerWidth > 992) {
    return (
      <header>
        <div className="shadow">
          <nav className="container row text-center justify-content-center mx-auto">
            {
              menus.map((item, i) => (
                <div key={i} className="col-md-2">
                  <a href={`/#${item.link}`} className='header-link'>{item.name}</a>
                </div>
              ))
            }
          </nav>
        </div>
        <nav className="container row text-center mx-auto align-items-center py-3">
          <div className="col-md-4">
            <a href='https://www.facebook.com/dimahbridalboutique/' target='_blank'>
              <BsFacebook size={22} color='white' />
            </a>
            <a href='https://wa.me/+917338839719?text=Hello!' target='_blank'>
              <BsWhatsapp size={22} color='white' className='ms-4' />
            </a>
            <a href="https://www.instagram.com/dimahfashion/?hl=en" target='_blank'>
              <BsInstagram size={22} color='white' className='ms-4' />
            </a>
          </div>
          <div className="col-md-3 p-3 position-relative">
            <img src={require('../../images/logo.png')} width={150} className='brand-lg' />
          </div>
          <div className="col-md-5 d-flex flex-xl-row flex-column">
            <a href="tel:917338839719" className='text-white d-flex mb-2 mb-xl-0'>
              <BsTelephoneFill size={22} color='white' className='me-2' />
              +91 7338839719
            </a>
            <div className="vr d-none d-xl-block mx-3" />
            <a href="mailto:dimahfashions@gmail.com" className='text-white d-flex'>
              <BsEnvelopeFill size={22} color='white' className='me-2' />
              dimahfashions@gmail.com
            </a>
          </div>
        </nav>
      </header>
    )
  }
  return (
    <Navbar expand={false} bg="primary" variant="dark" sticky='top'>
      <Container fluid>
        <Navbar.Brand href="#">
          <img src={require('../../images/logo-2.png')} width={100} className='brand' />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Header closeButton className='bg-primary'>
            <Offcanvas.Title>
              <img src={require('../../images/logo-2.png')} width={100} className='brand' />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {
                menus.map((item, i) => (
                  <a key={i} href={`/#${item.link}`} className='header-link'>{item.name}</a>
                ))
              }
            </Nav>
            <hr />
            <div >
              <a href="tel:917338839719" className='text-primary d-flex mb-2 mb-xl-0'>
                <BsTelephoneFill size={22} color='purple' className='me-2' />
                +91 7338839719
              </a>
              <div className="vr d-none d-xl-block mx-3" />
              <a href="mailto:dimahfashions@gmail.com" className='text-primary d-flex'>
                <BsEnvelopeFill size={22} color='purple' className='me-2' />
                dimahfashions@gmail.com
              </a>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header