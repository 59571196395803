import React from 'react'
import "../Apparels/Apparels.scss"
import Card from 'react-bootstrap/Card';
import { cards,card_title } from '../../data/constant';
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";

function Apparels() {

  const settings = {
    swipe :true,
    pauseOnHover :true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    arrows: false,
    centerPadding: 20,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // console.log(card_title,"card_title")

  return (
    <div className=' apparels' id='apparels'>
      <div className="apparels-heading">
        <div className="horizontal-line"></div>
        <span>APPARELS</span>
        <div className="horizontal-line"></div>
      </div>
      <Slider {...settings} >
        {
          cards.map((item, index) => (
            <Carousel.Item key={index} as={'a'} href={item.image} className='d-block' data-fancybox="image">
              <div className="apparels-card-section">
                <Card className='card' style={{ width: '20rem' }}>
                  <Card.Img className='card-image' variant="top" src={item.image} alt={index} />
                </Card>
                <h4 className="card-title">
                  {item.title}
                </h4>
              </div>
            </Carousel.Item>
          ))
        }
      </Slider>
    </div>
  )

}

export default Apparels