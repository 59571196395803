import Apparels from '../../Components/Apparels/Apparels'
import Banner from '../../Components/Banner/Banner'
import Service from '../../Components/BestService/Service'
import Highlights from '../../Components/Highlights/Highlights'
import Embroidery from  "../../Components/Embroidery/Embroidery.jsx"
import Testimonials from '../../Components/TESTIMONIALS/Testimonials'
import Footer from '../../Components/Footer/Footer'
import './Home.scss'

function Home() {
  return (
    <div>
      <Banner />
      <Highlights />
      <Apparels />
      <Service />
      <Embroidery />
      <Testimonials />
      <Footer />
    </div>
  )
}

export default Home