import React from 'react'
import "../Highlights/Highlights.scss"
import { FaShippingFast } from 'react-icons/fa';
import { GiTakeMyMoney } from 'react-icons/gi';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';

function Highlights() {
    return (
        <div className='highlights px-3 container'>
            <div className="row m-0 highlights-inner">
                <div className="col-lg-3 col-6 flex-column  px-2 flex-md-row text-center highlight ">
                    <FaShippingFast className="highlight-icon" />
                    <p>FREE SHIPPING</p>
                </div>
                <div className="col-lg-3 col-6 flex-column px-2  flex-md-row text-center highlight ">
                    <GiTakeMyMoney className="highlight-icon" />
                    <p>MONEY GUARANTEE</p>
                </div>
                <div className="col-lg-3 col-6 flex-column  px-2 flex-md-row text-center highlight">
                    <FaHandHoldingHeart className="highlight-icon" />
                    <p>ITEM GUARANTEE</p>
                </div>
                <div className="col-lg-3 col-6 flex-column  px-2 flex-md-row text-center highlight ">
                    <MdSupportAgent className="highlight-icon" />
                    <p>ONLINE SUPPORT</p>
                </div>
            </div>
        </div>
    )
}

export default Highlights