import React from 'react'
import "../../Components/TESTIMONIALS/Testimonials.scss"
import profile from "../../images/profile.icon.png"
import Slider from 'react-slick'
import { testimonials } from '../../data/constant'

function Testimonials() {
    return (
        <div className="container">
            <div className='testimonials '>
                <div className="testimonials-heading">
                    <div className="horizontal-line"></div>
                    <span>TESTIMONIALS</span>
                    <div className="horizontal-line"></div>
                </div>
                <div className="testimonials-content container">
                    <Slider arrows={false} dots={true}>
                        {
                            testimonials.map((item, index) => (
                                <div key={index} className="text-center testimonials-content-inner">
                                    <center>
                                        <img src={profile} className='profile-icon d-inline-block' alt="profile-icon" />
                                        <h3>{item.name}</h3>
                                        <p>{item.comment}</p>
                                    </center>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Testimonials