// import banner_one from "../images/Banner-01.jpg"
// import banner_two from "../images/Banner-02.jpg"
// const banners =  [banner_one, banner_two]

const banners = [ require('../images/Banner-01.jpg'), require('../images/Banner-02.jpg') ]

const cards = [
    {

        image: "https://www.shaadidukaan.com/vogue/wp-content/uploads/2020/03/Neha-Adhvik-Mahajan-bridal-blouse.jpg",
        title: "Bridal Blouses"
    },
    {

        image: "https://www.seasonsindia.com/assets/images/gowns-img.jpg",
        title: "Gown"
    },
    {

        image: "https://www.frontierraas.com/pub/media/catalog/product/cache/74910300c4c00f257771c5afa25168a6/f/r/frontier_raas-_lodhi6437.jpg",
        title: "Bridal Lehanga"
    },
    {

        image: "https://images.cbazaar.com/images/Mauve-Digital-Printed-A-Line-Kurti-KRBS02201291-u.jpg",
        title: "Kurtis"
    },
    {

        image: "https://static3.azafashions.com/tr:w-575,dpr-2,e-sharpen/uploads/product/1604601-0745398001611295024.jpg",
        title: "Saree "
    },
]
const embroidery_cards = [
    require('../images/services/01.png'),
    require('../images/services/02.png'),
    require('../images/services/03.png'),
    require('../images/services/04.png'),
    require('../images/services/05.png'),
    require('../images/services/06.png'),
    require('../images/services/07.png'),
    require('../images/gallery/01.png'),
    require('../images/gallery/02.png'),
    require('../images/gallery/03.png'),
    require('../images/gallery/04.png'),
    require('../images/gallery/05.png'),
    require('../images/gallery/06.png'),
    require('../images/gallery/07.png'),
    require('../images/gallery/08.png'),
    require('../images/gallery/09.png'),
    require('../images/gallery/10.png'),
    require('../images/gallery/11.png'),
    require('../images/gallery/12.png'),

]

const testimonials = [
    {
        name: "Kunooth Jasmine",
        comment: "Hi first of all faritha is a very cute and sweet person.then ur blouse aari work is so beautiful  and fitting is good for me thank u so much for ur very quick delivered  for me.100% is ur stitching  work  is very good.I give u more .becoz I like very much and its good fitting  thats it."
    },
    {
        name: "gayathri chaand",
        comment: "Hey Faridha, was a lil caught up so couldn't share feedback sooner. I wanted to tell you that my reception blouse turned out exactly the way I wanted and my most favourite was my engagement blouse, absolutely loved it. Thank you 😊"
    },
    {
        name: "Nisha Agarwal",
        comment: "My blouse was exactly how I had pictured in my mind. I am glad I found this store. Farida took a lot of efforts by coordinating with me about the smallest details. One thing i liked the most was, The prices were clearly explained while i visited."
    }
];

export {
    banners,
    cards,
    embroidery_cards,
    testimonials
}