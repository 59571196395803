import React from 'react'
import "../../Components/BestService/Service.scss"
import { RiScissorsCutFill } from 'react-icons/ri';
import { FaTape } from 'react-icons/fa';
import { TbRulerMeasure } from 'react-icons/tb';
import { GiResize, GiRolledCloth, GiShirtButton, GiSewingMachine } from 'react-icons/gi';
import { FaHandsHoldingCircle } from "react-icons/fa6"


function Service() {
    return (
        <div className='best-service' id='services'>
            <div className="service-title">
                <h1>BEST OF OUR SERVICE</h1>
                <p>Provide tailoring & fashion trends</p>
            </div>
            <div className="container">
                <div className="row service-box-inner">
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <RiScissorsCutFill className='box-icon' />
                            <p>CUTTING OUT</p>
                            <div className="box-number">1</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <TbRulerMeasure className='box-icon' />
                            <p> MEASUREMENTS</p>
                            <div className="box-number">2</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <GiResize className='box-icon' />
                            <p>RESIZE</p>
                            <div className="box-number">3</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <FaTape className='box-icon' />
                            <p>LENGTHENING</p>
                            <div className="box-number">4</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <GiSewingMachine className='box-icon' />
                            <p>RESTORATION</p>
                            <div className="box-number">5</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <GiRolledCloth className='box-icon' />
                            <p>ALTERATION</p>
                            <div className="box-number">6</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <GiShirtButton className='box-icon' />
                            <p>CUSTOM</p>
                            <div className="box-number">7</div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 my-4 px-md-4">
                        <div className="service-box">
                            <FaHandsHoldingCircle className='box-icon' />
                            <p>HAND CRAFTED</p>
                            <div className="box-number">8</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service