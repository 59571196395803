import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Home, Service } from "./Pages"
import '../src/App.scss'
import Layout from "./Layouts/Layout"
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Fancybox } from "@fancyapps/ui";

function App() {
  Fancybox.bind('[data-fancybox="image"]');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/services" element={<Service />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App